
import { Shop } from '@/types/shop'
import { Vue, Component } from 'vue-property-decorator'
import parseISO from 'date-fns/parseISO'
import isBefore from 'date-fns/isBefore'
import isEqual from 'date-fns/isEqual'
import formatISO from 'date-fns/formatISO'
import addDays from 'date-fns/addDays'

import ShopAutocomplete from '@/components/ShopAutocomplete.vue'
import DateSelect from '@/components/DateSelect.vue'

@Component({ components: { ShopAutocomplete, DateSelect } })
export default class HotpepperView extends Vue {
  shop = null as null | string
  shops: Shop[] = []
  next = null as null | string

  dateFrom = ''
  dateTo = ''
  errors: any = {}
  progress = 0
  text = ''
  isLoading = false

  get dates(): string[] {
    if (!this.dateFrom) return []
    let date = parseISO(this.dateFrom)
    if (!this.dateTo) return [this.dateFrom]
    const end = parseISO(this.dateTo)
    let rval: string[] = []

    while (isBefore(date, end) || isEqual(date, end)) {
      rval.push(formatISO(date, { representation: 'date' }))
      date = addDays(date, 1)
    }
    return rval
  }

  get postData() {
    let rval: { shop: Shop; date: string }[] = []
    const shop = this.shops.find(x => x.id === this.shop)
    const shops = shop ? [shop] : this.shops
    this.dates.forEach(date => {
      shops.forEach(shop => {
        rval.push({ shop, date })
      })
    })
    return rval
  }

  created() {
    this.fetchShop()
  }

  async fetchShop(url?: string) {
    const { results, next } = await (url
      ? this.$api.http.get(url)
      : this.$api.shops().list({ all: true }))
    this.shops = this.shops.concat(results)
    if (next) {
      this.fetchShop(next)
    }
  }

  async submit() {
    try {
      this.isLoading = true
      const data = this.postData
      const max = this.postData.length
      this.errors = {}
      const api = this.$api.sendWaku

      for (let i = 0; i < max; ++i) {
        const { shop, date } = data[i]
        console.log(shop.name, date)
        await api({ shop: shop.id, date })
        this.text = `${shop.name} ${date}`
        this.progress = ((i + 1) / max) * 100
      }
    } catch (err) {
      console.error(err)
      this.$toast.error('エラーが発生しました。')
      this.errors = this.$getValidationErrors(err as Error)
    }
    this.isLoading = false
  }
}
