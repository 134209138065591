
import { Vue, Component, Ref } from 'vue-property-decorator'
import { parse } from 'papaparse'
import moment from 'moment'
import ListView from '@/components/ListView.vue'
import DateSelect from '@/components/DateSelect.vue'
import ShopAutocomplete from '@/components/ShopAutocomplete.vue'
import CSVExportBtn from '@/components/CSVExport.vue'
import { Shop } from '@/types/shop'

const parseCSV = (file: File) => {
  return new Promise((resolve, reject) => {
    parse(file, {
      error: (err) => {
        reject(err)
      },
      complete: (results) => {
        return resolve(results.data)
      },
    })
  })
}

const transformCSV = (data: string[][], shops: Shop[]) => {
  const header = data[0]
  if (!header) return []
  const shopCodeIdx = header.findIndex((x) => String(x).match('コード'))
  const dateIdx = header.findIndex((x) => String(x).match('日'))
  const openIdx = header.findIndex((x) => String(x).match('開店'))
  const closeIdx = header.findIndex((x) => String(x).match('閉店'))

  const code2id: Map<string, Shop> = new Map()
  shops.forEach((shop) => {
    code2id.set(shop.code, shop)
  })
  data = data.slice(1)
  return data.map((row) => {
    const shop = code2id.get(row[shopCodeIdx])
    const date = moment(row[dateIdx])
    const openTime = row[openIdx] as string
    const closeTime = row[closeIdx] as string
    return {
      isValid: Boolean(shop && date.isValid() && openTime && closeTime),
      shop: shop?.id,
      shopCode: row[shopCodeIdx],
      shopName: shop?.name,
      date: date.isValid() ? date.format('YYYY-MM-DD') : '',
      openTime,
      closeTime,
    }
  })
}

@Component({
  components: { ListView, DateSelect, ShopAutocomplete, CSVExportBtn },
})
export default class OpenDayView extends Vue {
  @Ref() readonly listView!: ListView

  filterDefinition = {
    ordering: { type: String, default: '' },
    shop: { type: String, default: null },
    dateFrom: { type: String, default: null },
    dateTo: { type: String, default: null },
  }
  filter: null | Record<string, any> = null
  selected: number[] = []

  headers = [
    { text: '', value: 'check', width: 50 },
    { text: '店コード', value: 'shopCode', width: 60 },
    { text: '店舗', value: 'shopName', width: 200 },
    { text: '営業日', value: 'date', width: 100 },
    { text: '開店時間', value: 'openTime' },
    { text: '閉店時間', value: 'closeTime' },

    { text: '登録日時', value: 'createdAt', width: 150 },
  ]
  shops: Shop[] = []
  bulkData: {
    isValid: boolean
    shopCode: any
    shopName: any
    shop: any
    date: string
    openTime: string
    closeTime: string
  }[] = []
  isUploading = false
  dialog = false

  get csvHeaders() {
    return CSVHeaders
  }
  created() {
    this.fetchShop()
  }

  updateQuery() {
    if (this.filter !== null) this.listView.updateQuery(this.filter)
    this.selected = []
  }
  async onDelete() {
    if (!confirm('選択アイテムを削除しますか？')) return
    const api = this.$api.openDays
    try {
      await Promise.all(this.selected.map((id) => api(id).delete()))
      this.selected = []
    } catch (err) {
      console.error(err)
      this.$toast.error('削除中にエラーが発生しました。')
    }
    this.listView.reload()
  }
  onSelectedChange(checked: boolean, id: number) {
    const selected = this.selected
    const idx = selected.findIndex((x) => x === id)
    if (idx >= 0) {
      if (!checked) selected.splice(idx, 1)
    } else {
      if (checked) selected.push(id)
    }
  }
  async fetchShop(nextUrl?: string) {
    const { results, next } = await (nextUrl
      ? this.$api.http.get(nextUrl)
      : this.$api.shops().list())
    this.shops = this.shops.concat(results)
    if (next) this.fetchShop(next)
  }

  async onFileChanged(file: null | File) {
    console.log('onFileChanged', file)
    if (file) {
      const csvData = (await parseCSV(file)) as any[][]
      this.bulkData = transformCSV(csvData, this.shops)
    }
  }
  async upload() {
    this.isUploading = true
    try {
      await this.$api
        .openDays()
        .bulkUpload(this.bulkData.filter((x) => x.isValid))
      this.bulkData = []
      this.$toast.success('アップロードに成功しました。')
      this.dialog = false
    } catch (err) {
      console.error(err)
      this.$toast.error('アップロードに失敗しました。')
    }
    this.isUploading = false
  }

  downloadTemplate() {
    const filename = 'template.csv'
    let rows = [
      ['店コード', '営業日', '開店時間', '閉店時間'],
      ['101', '2021-05-01', '10:00', '18:00'],
    ]

    let content = rows.map((row) => row.join(',')).join('\r\n')
    let bom = new Uint8Array([0xef, 0xbb, 0xbf])
    let blob = new Blob([bom, content], { type: 'text/csv' })

    let a = document.createElement('a')
    a.download = filename
    // a.target = '_blank'
    a.href = window.URL.createObjectURL(blob)

    // firefox対策． chromeの場合は append/remove をしなくていいらしい
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

const CSVHeaders = [
  { key: 'id', label: '営業日ID' },
  { key: 'shopCode', label: '店コード' },
  { key: 'shopName', label: '店舗名' },
  { key: 'date', label: '日付' },
  { key: 'openTime', label: '開店時間' },
  { key: 'closeTime', label: '閉店時間' },
]
